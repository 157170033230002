.stepsCircle {
  > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .stepsCircleCentre {
    position: relative;
    width: 40%;

    .stepsCircleCentreContainer {
      position: relative;
      width: 100%;
      padding-top: 100%;
      
      .stepsCircleBackground {
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        border: 5px solid #D11A6D;
        border-radius: 50%;
      }
  
      .stepsCircleItem {
        position: absolute;
        width: 20%;
        height: 20%;
        border-radius: 50%;
        border: 5px solid #1f1f1f;
        transition: all 0.3s;
        transform-origin: center;
        cursor: pointer;

        svg {
          width: 50%;
          height: 50%;
          color: #1f1f1f;
          padding: 25%;
          transition: all 0.3s;
        }

        &:hover,
        &.stepsCircleItemHover {
          background: #D11A6D !important;
          border-color: #D11A6D !important;
          transform: scale(1.1);

          svg {
            color: #ffffff !important;
          }
        }
      }

      .stepsCircleContent {  
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .stepsCircleLogo {
          margin-bottom: 10px;
        }
    
        .stepsCircleText {
          font-size: 18px;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }

  .stepsCircleRight,
  .stepsCircleLeft {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 30%;

    .stepsCircleStepItemWrapper {
      background: #f1f1f1;
      padding: 20px 25px;
      border-radius: 15px;
      transition: all 0.3s;
    }

    .stepsCircleStepItemWrapperMobile {
      display: none;
    }
  }

  .stepsCircleLeft {
    padding-right: 60px;
    flex-direction: column-reverse;
  }

  .stepsCircleRight {
    padding-left: 60px;
  }
}

.stepsCircleStepItem {
  display: flex;
  flex-direction: row;
  gap: 15px;
  transition: all 0.3s;

  .stepsCircleStepItemIcon {
    margin-top: 2px;

    &.noMargin {
      margin-top: 0;
    }
  }

  .stepsCircleStepItemTitle {
    display: flex;
    flex-direction: row;

    > div {
      font-size: 16px;
      line-height: '24px';
      font-weight: 600;
      flex-grow: 1;
      padding-right: 20px;      
    }

    svg {
      flex-shrink: 1;
      transition: all 0.2s;
      margin-top: 3px;
    }
  }

  &.stepsCircleStepItemHover {
    .stepsCircleStepItemTitle {  
      svg {
        transform: rotate(90deg);
      }
    }
  }

  .stepsCircleStepItemContent {
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    margin-top: 10px;
    color: #3f3f3f;
  }

  .stepsCircleStepItemLink {
    transition: all 0.3s;
    overflow: hidden;

    a {
      font-size: 14px;
      color: #D11A6D;
      text-decoration: none !important;

      svg {
        margin-left: 10px;
        margin-bottom: -1px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .stepsCircle {
    .stepsCircleLeft {
      display: none;
    }

    .stepsCircleCentre {
      order: 1;
      width: 50%;
    }

    .stepsCircleRight {
      order: 2;
      width: 50%;

      .stepsCircleStepItemWrapperMobile {
        display: block;
      }
    }
  }
}

@media (max-width: 1200px) {
  .stepsCircle {
    > div:first-of-type {
      flex-direction: column;
    }

    .stepsCircleLeft {
      display: none;
    }

    .stepsCircleCentre {
      width: 100%;
      padding-left: 22%;
      padding-right: 22%;

      .stepsCircleCentreContainer {
        .stepsCircleContent {
          .stepsCircleText {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }

    .stepsCircleRight {
      width: 100%;
      padding-left: 0;
      padding-top: 80px;
    }
  }
}

@media (max-width: 1000px) {
  .stepsCircle {
    .stepsCircleCentre {
      padding-left: 12%;
      padding-right: 12%;

      .stepsCircleCentreContainer {
        .stepsCircleContent {
          .stepsCircleLogo {
            margin: 0;
          }

          .stepsCircleText {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .stepsCircle {
    .stepsCircleCentre {
      padding-left: 0;
      padding-right: 0;

      .stepsCircleCentreContainer {
        .stepsCircleContent {
          .stepsCircleLogo {
            margin: 0;
          }

          .stepsCircleText {
            display: none;
          }
        }
      }
    }
  }
}